@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "node_modules/font-awesome/scss/font-awesome";
@import "resources/assets/css/bootstrap-datetimepicker";
@import "resources/assets/css/daterangepicker";
@import "resources/assets/css/bootstrap-multiselect";
@import "resources/assets/css/tablesorter";
@import "resources/assets/css/dropzone";

body {
  padding-top: 70px;
  font-size: 13px;
}

table {
  font-size: 12px;
}

.huge {
    font-size: 30px;
}

.large-font {
  font-size: 20px;
  font-weight: bold;
}

.table-width-minimum {
  width: 1px;
}

.dropzone {
  border: 2px dashed #0087F7;
  border-radius: 5px;
  background: white;
  margin-bottom: 30px;
}

.dropzone .dz-clickable {
  cursor: pointer;
}

.dropzone .dz-message {
  font-weight: 400;
  font-size: 200%;
  margin: 1.1em 0;
}

.img-thumbnail {
  padding: 6px;
  margin: 8px;
}

.thumb {
  margin-bottom: 30px;
}

.panel-green {
    border-color: #5cb85c;
}

.panel-green .panel-heading {
    border-color: #5cb85c;
    color: #fff;
    background-color: #5cb85c;
}

.panel-green a {
    color: #5cb85c;
}

.panel-green a:hover {
    color: #3d8b3d;
}

.panel-red {
    border-color: #d9534f;
}

.panel-red .panel-heading {
    border-color: #d9534f;
    color: #fff;
    background-color: #d9534f;
}

.panel-red a {
    color: #d9534f;
}

.panel-red a:hover {
    color: #b52b27;
}

.panel-yellow {
    border-color: #f0ad4e;
}

.panel-yellow .panel-heading {
    border-color: #f0ad4e;
    color: #fff;
    background-color: #f0ad4e;
}

.panel-yellow a {
    color: #f0ad4e;
}

.panel-yellow a:hover {
    color: #df8a13;
}

.table-clickable td {
  cursor: pointer;
  padding: 0;
  a {
    display: block;
    //padding: 5px;
  }
}

.section-header {
  border-top: solid #cccccc 1px;
  border-bottom: solid #cccccc 1px;
  font-weight: bold;
  background-color: #cfdbe9;
  margin-bottom: 5px;
  padding: 5px;
}

.form-fake {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.428571429;
  color: #555555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  cursor: not-allowed;
}

.selectWidth {
  width: auto;
  margin:10px;
}

#map {
  width: 100%;
  height: 350px;
}

@media print {
  body {
    padding-top: 0;
  }
  a[href]:after {
    content: none;
  }

  .breadcrumb {
    display: none;
  }

  .nav-tabs {
    display: none;
  }
}
